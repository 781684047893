export default function ImportantMessage(props: any) {
    const { children } = props;
    return (
        <div
            className="important-message"
            style={{
                padding: `5px`,
                border: `4px solid #000`,
                marginBottom: `20px`,
                color: 'red',
                // color: '#000',
                lineHeight: '1.2em',
            }}
        >
            {children}
        </div>
    );
}